import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface TranslationOptions {
  [key: string]: string | number | boolean;
}

@Pipe({
  standalone: true,
  name: 'translateWithFallback',
})
export class TranslateWithFallbackPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    key: string,
    fallback: string,
    options: TranslationOptions = {},
  ): string {
    const translation = this.translateService.instant(key);
    return translation === key
      ? this.translateService.instant(fallback, options)
      : translation;
  }
}

import { booleanAttribute, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  IconList,
  IeIconsComponent,
} from '@ieShared/components/ie-icons/ie-icons.component';

export type ButtonType = 'primary' | 'secondary' | 'outline' | 'link';
export type ButtonSize = 'md' | 'sm' | 'lg' | 'xs';

@Component({
  selector: 'ie-button',
  standalone: true,
  imports: [NgClass, IeIconsComponent],
  templateUrl: './ie-button.component.html',
  styleUrl: './ie-button.component.scss',
})
export class IeButtonComponent {
  @Input() buttonType: ButtonType = 'primary';
  @Input() size: ButtonSize = 'md';
  @Input() loading: boolean | null = false;
  @Input() icon!: IconList;
  @Input() onlyIcon = false;
  @Input() disabled = false;
  @Input() type = 'submit';
  @Input({ transform: booleanAttribute }) fullWidth = false;
}

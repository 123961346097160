import { AbstractControl, FormGroup } from '@angular/forms';

export const markAllAsTouched = (formGroup: FormGroup) => {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormGroup) {
      markAllAsTouched(control);
    } else {
      control!.markAsTouched({ onlySelf: true });
    }
  });
};

export function removeFieldError(control: AbstractControl, errorKey: string) {
  if (control && control.errors && control.errors[errorKey]) {
    const updatedErrors = { ...control.errors };
    delete updatedErrors[errorKey];
    control.setErrors(
      Object.keys(updatedErrors).length > 0 ? updatedErrors : null,
    );
    control.updateValueAndValidity();
  }
}

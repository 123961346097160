import { Component, Input } from '@angular/core';

export type IconList =
  | 'arrow-down-s-line'
  | 'arrow-left-s-line'
  | 'arrow-right-s-line'
  | 'arrow-up-s-line'
  | 'expand-up-down-fill'
  | '3d-cube-scan'
  | '3d-square'
  | '3square'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'act'
  | 'activity'
  | 'add-circle'
  | 'add-square'
  | 'add'
  | 'arrow-left-right'
  | 'arrow-3'
  | 'arrow-down-2'
  | 'arrow-left-2'
  | 'arrow-right-2'
  | 'arrow-right-3'
  | 'arrow-square'
  | 'arrow-up-2'
  | 'arud'
  | 'attach-square'
  | 'bank'
  | 'barcode'
  | 'book'
  | 'box-2'
  | 'box'
  | 'briefcase'
  | 'building-3'
  | 'building-4'
  | 'building'
  | 'buildings-2'
  | 'buildings'
  | 'buliding'
  | 'calculator'
  | 'calendar-2'
  | 'calendar-add'
  | 'calendar-edit'
  | 'calendar'
  | 'cert'
  | 'chart-2'
  | 'chart-success'
  | 'chart'
  | 'chart1'
  | 'check1'
  | 'clock'
  | 'close-circle'
  | 'close-square'
  | 'close'
  | 'cloud-drizzle'
  | 'computing'
  | 'contentedit'
  | 'convert-3d-cube'
  | 'copy-success'
  | 'copy'
  | 'courthouse'
  | 'creative-commons'
  | 'cup'
  | 'danger'
  | 'diagram'
  | 'dislike'
  | 'document-copy'
  | 'document-filter'
  | 'done-v'
  | 'drop'
  | 'edit'
  | 'export'
  | 'eye-slash'
  | 'eye'
  | 'favorite-chart'
  | 'fill'
  | 'filter-add'
  | 'filter-edit'
  | 'flag-2'
  | 'flag'
  | 'flash-slash'
  | 'flash'
  | 'format-square'
  | 'frame'
  | 'glass'
  | 'global'
  | 'globe'
  | 'gps'
  | 'graph'
  | 'group'
  | 'hashtag'
  | 'health'
  | 'heart'
  | 'home-2'
  | 'home-hashtag'
  | 'home-trend-down'
  | 'home-trend-up'
  | 'home'
  | 'homev2'
  | 'hospital'
  | 'house-2'
  | 'house'
  | 'import'
  | 'info-circle'
  | 'issu'
  | 'judge'
  | 'key-square'
  | 'lamp-charge'
  | 'lamp-on'
  | 'layer'
  | 'ledger'
  | 'like'
  | 'link-circle'
  | 'link'
  | 'login'
  | 'logout'
  | 'magick'
  | 'map'
  | 'map1'
  | 'maximize-2'
  | 'maximize-3'
  | 'maximize'
  | 'medal-star'
  | 'medal'
  | 'menu'
  | 'message-add'
  | 'message-edit'
  | 'message-minus'
  | 'messages'
  | 'milk'
  | 'minus-cirlce'
  | 'minus-square'
  | 'minus'
  | 'monitor'
  | 'moon'
  | 'more-circle'
  | 'more-square'
  | 'note-2'
  | 'note'
  | 'noteempty'
  | 'notification-bing'
  | 'notification-status'
  | 'notification'
  | 'people'
  | 'personalcard'
  | 'picture-frame'
  | 'plan'
  | 'presention-chart'
  | 'Property3196'
  | 'ranking'
  | 'refresh-2'
  | 'remove'
  | 'report'
  | 'review'
  | 'rotate-left'
  | 'routing-2'
  | 'routing'
  | 'save-2'
  | 'save-add'
  | 'save-minus'
  | 'save'
  | 'search-normal'
  | 'search-zoom-in'
  | 'security-safe'
  | 'security-user'
  | 'security'
  | 'send-2'
  | 'setting-2'
  | 'setting-3'
  | 'setting-5'
  | 'setting'
  | 'share'
  | 'shield'
  | 'shopping-bag'
  | 'shopping-cart'
  | 'SideCollapse'
  | 'SideExpand'
  | 'sms-tracking'
  | 'sort'
  | 'star'
  | 'stats1'
  | 'status-up'
  | 'sticker'
  | 'sun'
  | 'sun1'
  | 'tag-cross'
  | 'target'
  | 'task-square'
  | 'task'
  | 'tick-circle'
  | 'tick-square'
  | 'timer'
  | 'track'
  | 'track2'
  | 'trash'
  | 'tree'
  | 'trend-down'
  | 'trend-up'
  | 'truckgo'
  | 'unlimited'
  | 'user-add'
  | 'user-edit'
  | 'user-minus'
  | 'user-octagon'
  | 'user-remove'
  | 'user-search'
  | 'user-square'
  | 'user-tick'
  | 'user'
  | 'usersv2'
  | 'verify'
  | 'edit2'
  | 'more-svgrepo-com'
  | 'side-expand'
  | 'side-collapse'
  | 'loading'
  | 'hide'
  | 'show'
  | 'file-add'
  | 'retry';

@Component({
  selector: 'ie-icons',
  standalone: true,
  imports: [],
  templateUrl: './ie-icons.component.html',
  styleUrl: './ie-icons.component.scss',
})
export class IeIconsComponent {
  @Input() icon!: IconList;
  @Input() width: number = 24;
  @Input() height: number = 24;
}
